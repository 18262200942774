import React from "react"
import { motion } from "framer-motion";

import cover from "../assets/images/projects/ibb-kultur/cover.png";
import ibb1 from "../assets/images/projects/ibb-kultur/ibb1.png";
import ibb2 from "../assets/images/projects/ibb-kultur/ibb2.png";
import ibb3 from "../assets/images/projects/ibb-kultur/ibb3.png";
import ibb5 from "../assets/images/projects/ibb-kultur/ibb5.png";
import ibb6 from "../assets/images/projects/ibb-kultur/ibb6.png";
import ibb7 from "../assets/images/projects/ibb-kultur/ibb7.png";
import ibb8 from "../assets/images/projects/ibb-kultur/ibb8.png";
import ibb9 from "../assets/images/projects/ibb-kultur/ibb9.png";
import ibb10 from "../assets/images/projects/ibb-kultur/ibb10.png";
import ibb11 from "../assets/images/projects/ibb-kultur/ibb11.png";

export default function ProjectCard({ img, title, type, date }) {
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-4'>
          <h3 className='text-6xl'>İBB Kültür</h3>
          <p> Introducing the vibrant new face of Istanbul Metropolitan Municipality's Culture Department! Our doors are wide open, welcoming all voices, perspectives, and passions. With this fresh logo, we put YOU, the heart of our incredible city, front and center. Get ready to embark on a journey of culture, creativity, and community!</p>
        </div>
        <div className="flex flex-col gap-4">
            <img src={cover} alt="cover" />
            <img src={ibb3} alt="logo" />
            <img src={ibb1} alt="logo" />
            <img src={ibb2} alt="photo" />
            <img src={ibb5} alt="logo"/>
            <img src={ibb6} alt="photo"/>
            <img src={ibb7} alt="photo"/>
            <img src={ibb8} alt="photo"/>
            <img src={ibb9} alt="photo"/>
            <img src={ibb10} alt="photo"/>
            <img src={ibb11} alt="photo"/>
        </div>
      </div>
    )
  }