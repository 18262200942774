// packages
import { motion } from "framer-motion";

// components
import Header from "../components/Header";
import Hero from "../components/Hero";
import WorksList from "../components/WorksList";
import Footer from "../components/Footer";
import Circle from "../components/Circle";

// assets
import Values from "../components/Values";
import ServicesList from "../components/ServicesList";


function Home() {
  return (
    <div className="relative overflow-hidden">
      <motion.div 
      className="absolute top-[-320px] right-0 -z-10 mix-blend-multiply"
      initial={{ opacity: 0, y: -600 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.7, damping: 20 }}
      >
        <Circle />
      </motion.div>
      <div className="w-full flex flex-col gap-16 md:gap-32 px-8">
        <Header
          colorStyle={"light"}
          logoColor={"black"}
          workStyle={"lightDisabled"}
          servicesStyle={"lightDisabled"}
          contactStyle={"lightInactive"}
        />

        {/* content */}
        <Hero />
        <WorksList />
        <ServicesList />
        <Values />
      </div>

      <Footer className="absolute bottom-0" />
    </div>
  );
}

export default Home;
