import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import Header from "../../components/Header";
import Hero from "../../components/Hero";
import Footer from "../../components/Footer";
import Circle from "../../components/Circle";
import ProjectCard from "../../components/ProjectCard";

export default function Kultur() {
  return (
  <div className="relative overflow-hidden">
       <motion.div 
       className="absolute top-[-320px] right-0 -z-10 mix-blend-multiply"
       initial={{ opacity: 0, y: -600 }}
       animate={{ opacity: 1, y: 0 }}
       transition={{ duration: 0.7, damping: 20 }}
       >
         <Circle />
       </motion.div>
       <div className="w-full flex flex-col gap-16 md:gap-32 px-8">
         <Header
           colorStyle={"light"}
           logoColor={"black"}
           workStyle={"lightDisabled"}
           servicesStyle={"lightDisabled"}
           contactStyle={"lightInactive"}
         />
 
         {/* content */}
         <ProjectCard />
       </div>
 
       <Footer className="absolute bottom-0" />
     </div>
  );
}
