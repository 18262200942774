import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import '../src/global.css'

// pages
import Home from './pages/Home';
import Works from './pages/Works';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Soon from './pages/Soon';

// projects
import Kultur from './pages/projects/ibb-kultur';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path='work' element={<Works />}/>
      <Route path='ibb-kultur' element={<Kultur />}/>
      <Route path="services" element={<Services />}/>
      <Route path='contact' element={<Contact />}/>
      <Route path='soon' element={<Soon />}/>
    </Routes>
  </Router>
);
