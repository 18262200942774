import React from "react";
import { Link } from "react-router-dom";
import arrowRight from '../assets/icons/arrow-right-black.svg'

export default function WorksListItem({
  projectTitle,
  projectType,
  protjectDate,
}) {
  return (
    // <a className="grid grid-cols-8 gap-4 text-black py-4 text-base border-b border-black cursor-pointer hover:bg-neutral-500/10 group" href="#">
    //   <p className="col-start-1 flex gap-2 items-center group-hover:pl-1">
    //     <span className="hidden group-hover:block"><img src={arrowRight}/></span>
    //     {projectTitle}
    //     </p>
    //   <p className="col-start-6">{projectType}</p>
    //   <p className="col-start-8 justify-self-end">{protjectDate}</p>
    // </a>
    <div
      className="flex justify-between  gap-4 text-black py-4 text-base border-b border-black cursor-pointer hover:bg-neutral-500/10 group transition duration-200"
      href="#"
    >
      <div className="col-start-1 flex gap-2 items-center group-hover:pl-1 transition duration-200">
          <img src={arrowRight} alt="icon" className="hidden group-hover:block"/>
        {projectTitle}
      </div>
      <div className="flex gap-4">
        <p className="col-start-6 pr-4">{projectType}</p>
      </div>
    </div>
  );
}
